import * as React from "react"

import CTA from "../components/cta"
import Layout from "../components/layout"
import Features from "../components/features"
import About from "../components/about"
import Services from "../components/services"
import Testimonials from "../components/testimonials"
import Contact from "../components/contact"

const IndexPage = () => (
  <Layout>
    <CTA />
    <Features />
    <About />
    <Services />
    <Testimonials />
    <Contact>
      <form
        method="post"
        action="/contact-success#contact-us"
        name="contact"
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="contact" />
        Your Email: <input type="text" name="email" /> Your Name:
        <input type="text" name="name" /> Subject:
        <input type="text" name="subject" /> Message:
        <textarea rows={3} cols={40} name="message"></textarea>
        <button type="submit" name="submit">
          Send Message
        </button>
      </form>
    </Contact>
  </Layout>
)

export default IndexPage
